* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  /* box-shadow: inset 0 0 10px rgba(0,0,0,0.25); */
  /* background: none;  */
  opacity: 0;
}
 
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: gray; 
}

::-webkit-scrollbar-thumb:hover {
  background: gray; 
} 
